import React from 'react';
import { Security } from '@okta/okta-react';
import { OAuthError, OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import urlcat from 'urlcat';

const CONFIG = {
  passport: {
    url: 'https://stage.passport.bandwidth.com',
  },
};

const oktaAuthOptions: OktaAuthOptions = {
  // TODO: Update the client id for your application.
  clientId: '0oat0klgwqF2SEX5p0h7',
  issuer: 'https://stage.login.bandwidth.com/oauth2/ausqxr6vbsMh6tmN30h7',
  redirectUri: '/okta/callback',
  postLogoutRedirectUri: '/loggedOut',
  scopes: ['openid', 'oneid', 'profile', 'email'],
  pkce: true,
  devMode: process.env.NODE_ENV === 'development',
  cookies: {
    secure: true,
  },
  tokenManager: {
    storage: 'sessionStorage',
  },
  transformAuthState: async (oa: Partial<OktaAuth>, authState) => {
    if (!authState.isAuthenticated) {
      return authState;
    }
    const sessionExists = await oa?.session?.exists();
    if (!sessionExists) {
      oa?.tokenManager?.clear();
    }

    return { ...authState, isAuthenticated: sessionExists };
  },
};

// Cypress works better if we don't have extra requirements around auth.
if ((window as any).Cypress) {
  delete oktaAuthOptions.transformAuthState;
}

export const oktaAuth = new OktaAuth(oktaAuthOptions);

const OktaAuthSecurity: React.FC = ({ children }) => {
  const customAuthHandler = async (oa: OktaAuth) => {
    return oa.token
      .getWithoutPrompt({
        responseType: ['id_token', 'token'],
      })
      .then((res) => {
        const { tokens } = res;
        oa.removeOriginalUri();
        oa.tokenManager.setTokens(tokens);
        oa.authStateManager.updateAuthState();
      })
      .catch((err) => {
        if (
          !(window as any).Cypress &&
          err instanceof OAuthError &&
          err.errorCode === 'login_required'
        ) {
          const originalUri = oa.getOriginalUri();
          window.location.href = urlcat(CONFIG.passport.url, 'login', {
            returnTo: originalUri,
          });
          return;
        }
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler}>
      {children}
    </Security>
  );
};

export default OktaAuthSecurity;
