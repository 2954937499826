import React, { forwardRef } from 'react';
import { NavButton, NavButtonProps } from '@forge/foundry';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';

interface NavItemProps extends NavLinkProps {
  /** Allow you to add inner nav button props */
  NavButtonProps?: NavButtonProps;
  to: string;
}

/**
 * A navigation item
 */
const NavLinkItem = forwardRef<any, NavItemProps>(
  ({ NavButtonProps: NBProps, exact, to, ...rest }, ref) => {
    const location = useLocation();
    const active = exact
      ? location.pathname === to
      : location.pathname.startsWith(to);

    return (
      <NavButton
        exact={exact}
        to={to}
        active={active}
        // @ts-ignore
        component={NavLink}
        {...rest}
        ref={ref}
      />
    );
  },
);

export default NavLinkItem;
